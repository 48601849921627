import Vue from 'vue'
import App from './App.vue'
import dayjs from 'dayjs'
import config from './config/index.js'
import router from './router'
import Env from './utils/env.js'
import './plugins/element-components'
import './assets/element-theme/index.css'
import './assets/css/base.scss'
import './assets/css/iconfont.scss'

Vue.use(Env)
Vue.config.productionTip = false
Vue.prototype.$config = config
Vue.prototype.$dayjs = dayjs

// 千位符
Vue.filter('numFormat', function (num) {
  if (num) {
    num = num.toString().split('.')
    const arr = num[0].split('').reverse()
    let res = []
    for (let i = 0; i < arr.length; i++) {
      if (i % 3 === 0 && i !== 0) {
        res.push(',')
      }
      res.push(arr[i])
    }
    res.reverse()
    if (num[1]) {
      res = res.join('').concat('.' + num[1])
    } else {
      res = res.join('')
    }
    return res
  } else {
    return ''
  }
})

// 日期格式化
// formatString = YYYY-MM-DD | YYYY-MM-DD HH:mm:ss
Vue.filter('dateFormat', function (date, formatString) {
  if (date) {
    return dayjs(date).format(formatString)
  } else {
    return ''
  }
})
Vue.filter('toInt', function (value) {
  return Math.floor(value)
})

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
