import Vue from 'vue'
import VueRouter from 'vue-router'
import { SetToken, GetToken } from '../utils/auth'

Vue.use(VueRouter)

/* 防止连续点击多次路由报错 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '*',
    name: '404',
    component: () => import('@/views/error/404.vue'),
    meta: {
      title: '404'
    }
  },
  {
    path: '/500',
    name: '500',
    component: () => import('@/views/error/500.vue'),
    meta: {
      title: '500'
    }
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/error/403.vue'),
    meta: {
      title: '403'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register/index.vue'),
    meta: {
      title: '注册'
    }
  },
  {
    path: '/forgetpwd',
    name: 'forgetpwd',
    component: () => import('@/views/forget-password/index.vue'),
    meta: {
      title: '重置密码'
    }
  },
]

const router = new VueRouter({
  mode: 'history', //  history hash
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

const contexts = require.context('./router-map', true, /\.js$/)
contexts.keys().forEach((value) => {
  const moduleConfig = contexts(value)
  const routeList = moduleConfig.default || moduleConfig // 默认导出或直接导出路由配置数组
  router.addRoutes(routeList)
})

router.beforeEach((to, from, next) => {
  document.title = ''
  if (to.meta) {
    document.title = to.meta.title
  }
  if (to.query.token) {
    SetToken(to.query.token)
  }
  if (!GetToken() && Vue.prototype.$env.isSit) {
    SetToken('5c1ae1bb040c4907a8aae4a3f9a97a98')
  }
  // console.log('beforeEach to:', to, 'from:', from)
  next()
})

router.afterEach((to, from) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  window.pageYOffset = 0
})

export default router
