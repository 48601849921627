<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {}
  },
  methods: {
    // 重置水印
    resetWaterMark() {
      watermark.load({ watermark_txt: '测试水印 reset' })
    },
    // 更新水印
    updateWaterMark() {
      watermark.load({ watermark_txt: '测试水印 update' })
    },
    // 删除水印
    removeWaterMark() {
      try {
        watermark.remove()
      } catch (error) {}
    }
  },
  mounted() {
    // watermark.init({ watermark_txt: '测试水印 mounte' })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
}
</style>
