const goods = [
  {
    path: '/tms',
    name: 'tms',
    component: () => import('@/views/index.vue'),
    children: [
      {
        path: 'goods',
        component: () => import('@/views/goods/detail.vue'),
        meta: {
          title: '产品'
        }
      }
    ]
  },
  {
    path: '/goods',
    name: 'goods',
    component: () => import('@/views/goods/detail.vue'),
    meta: {
      title: '产品'
    }
  },
  {
    path: '/category',
    name: 'cateogry',
    component: () => import('@/views/category/index.vue'),
    meta: {
      title: '产品分类'
    }
  }
]
export default goods
// 商品及分类
