const customsbill = [
  {
    path: '/customsbill/index',
    name: 'customsbill',
    component: () => import('@/views/customsbill/index.vue'),
    meta: {
      title: '提单号' // 管理员提单号
    }
  },
  {
    path: '/customsbill/reports',
    name: 'customsbillreports',
    component: () => import('@/views/customsbill/reports.vue'),
    meta: {
      title: '提单号统计' // 管理员提单统计表
    }
  },
  {
    path: '/customsbill/agency',
    name: 'customsbillagency',
    component: () => import('@/views/customsbill/agency.vue'),
    meta: {
      title: '提单号' // 代理商提单号
    }
  }
]
export default customsbill
// 提单