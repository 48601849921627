const expressage = [
  {
    path: '/expressage/index',
    name: 'expressage',
    component: () => import('@/views/expressage/index.vue'),
    meta: {
      title: '快递单号计算'
    }
  },
  {
    path: '/expressage/admin',
    name: 'expressageadmin',
    component: () => import('@/views/expressage/admin.vue'),
    meta: {
      title: '快递单号计算'
    }
  },]

export default expressage
// 快递单号计算