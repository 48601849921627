const excel = [
  {
    path: '/excel/exciseexport',
    name: 'booker',
    component: () => import('@/views/excel/excise-export.vue'),
    meta: {
      title: '消費税輸出免税不適用連絡一覧表'
    }
  }
]

export default excel
// excel
