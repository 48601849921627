let APIURL = ''
let NETAPIURL = ''

switch (process.env.NODE_ENV) {
  case 'sit':
    APIURL = 'https://api.199.jp'
    NETAPIURL = 'https://api.kuaidi.jp'
    break
  case 'production':
    APIURL = 'https://api.199.jp'
    NETAPIURL = 'https://api.kuaidi.jp'
    break
  case 'new':
    APIURL = 'http://15.152.204.230:8082'
    NETAPIURL = 'http://api.pwa.jp'
    break

}
export const TOKEN_KEY = 'token'
// 每页记录条数
export const PAGESIZE = 10
// 每页记录条数选择器
export const PAGESIZELIST = [10, 20, 50, 100]
// 分页空间样式
export const PAGE_LAYOUT = 'total,  prev, pager, next, sizes,jumper'

export default {
  NETAPIURL,
  APIURL
}
